const ClubProductsInfo = {
  es: {
    face: [
      {
        title: "The Absolute",
        summary:
          "La Crema antiedad con una innovadora tecnología natural que controla los procesos del envejecimiento del GEN NC886 para regenerar y rejuvenecer la piel.",
        text: "El Ácido Hialurónico de bajo peso molecular más sus 12 componentes biológicamente activos luchan eficazmente contra el envejecimiento, neutralizan los radicales libres y rellenan las arrugas. Una explosión de nutrientes, vitaminas y proteínas que estimulan las fibras encargadas de la producción de colágeno regenerando las células y resultando una piel flexible, tersa y sin manchas.<br><br><li> Restructura todo tipo de pieles desde dentro</li> <li> Estimula la producción de colágeno y la regeneración de la piel</li> <li> Reduce las arrugas nasolabiales en un 19%</li> <li> Aumenta la nutrición de la piel en un 14%</li> <li> Reduce las manchas en un 10%</li>",
        id: 53123,
        sendingClub: 53275,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_absolute.png&width=400&height=700",
      },
      {
        title: "The Cure",
        summary:
          "Serum definitivo para la cura de la piel y a cualquier edad. Transforma tu piel con 21 aceites únicos 100% naturales y Bakuchiol para reparar y tratar las arrugas más profundas.",
        text: "4 claves para para convertirlo en una adicción:<p><strong>BAKUCHIOL</strong>: La alternativa natural al retinol que estimula la producción de colágeno y elastina, inhibe la producción de melanina y repara, redensifica y trata hasta las arrugas más profundas.</p><p><strong>EXPLOSION DE VITAMINAS</strong>: Acción combinada de vitaminas B, C, E y K con resultados rápidamente visibles. Aporta luminosidad y unifica la piel para un efecto 8h de sueño.</p><p><strong>ENZIMA PRODIGIOSA</strong>: La Superóxido Dismutasa es un renovador celular que cambiará por completo tu piel. Neutraliza los radicales libres y revierte los signos del envejecimiento.</p><p><strong>BOTOX VEGETAL + ACIDOS GRASOS</strong>: Una sinergia única para rejuvenecer tu piel. Aumenta la firmeza alisando las arrugas profundas y superficiales.</p> <li> Piel deslumbrant</li><li> Rellena las arruga</li><li> Estimula la formación de colágeno y ácido hialurónico</li>",
        id: 53252,
        sendingClub: 53224,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_cure.png&width=400&height=700",
      },
      {
        title: "Wondermask",
        summary:
          "Mascarilla facial 5 en 1 para una piel perfecta sin necesidad de filtros. Detoxifica, equilibra, afina los poros, exfolia y combate los efectos de la contaminación.",
        text: "Mascarilla creada a base de complejos naturales: Higo Chumbo, Rosa Canina, Manzanilla, Girasol, Jengibre y el poder de nuestra arcilla pura del Mediterráneo harán que tu piel esté perfecta en tan solo unos minutos consiguiendo una piel lisa y limpia.<br><br><li> Detoxifica y equilibra la pie</li><li> Cierra y afina los poro</li><li> Acaba con el aspecto de cara hinchada</li><li> Ilumina y unifica el tono</li><li> Protege contra la blue light de las pantalla</li><li> Revitaliza y remineraliza la piel</li>",
        id: 53102,
        sendingClub: 53226,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_wondermask.png&width=400&height=700",
      },
      {
        title: "THE REAL C ",
        summary:
          "El escudo antienvejecimiento más poderoso con Vitamina C 100 % Pura (Ácido Ascórbico) al 20%. Hasta 178 veces más potente que los derivados* de la Vitamina C, trabaja la flacidez, manchas, arrugas y la piel apagada. Envase EcoRecarga </ br><small>*Tras realizar ensayos de estabilidad comparando el ascorbato THD y el ácido L-ascórbico.</small>",
        text: "<p> es un tratamiento completo para dar luminosidad y elasticidad a la piel, reducir arrugas y manchas gracias a la Vitamina C más pura. Ahora en formato 30 días: contiene 30 perlas monodosis biodegradables y envasadas en atmósfera sin oxígeno en concentración al 20% de vitamina C 100% pura (Ácido Ascórbico).Este super-cosmético hasta 178 veces más potente que los derivados* de la vitamina C y acompañado de Vitamina E penetra en las capas más profundas de la piel mejorando la síntesis de colágeno. Esta sinergia forma un complejo antioxidante que actúa como escudo ante los radicales libres: Protegiendo del daño celular, trabajando las arrugas y la firmeza del óvalo facial, potenciando la elasticidad y reduciendo visiblemente las manchas.</p><p>Resultados clínicos probados a las 8 semanas:</p<li>Aumenta la firmeza y elasticidad de la piel hasta un 6,7%</li><li>Mejora la luminosidad de la piel hasta en un 15,1 %</li><li>Disminuye la intensidad del color y el tamaño de las manchas oscuras hasta un 22,9%</li><li>Reduce la profundidad de las arrugas hasta un 22,2%</li><li>Tratamiento para 4 semanas</li><p>*Tras realizar ensayos de estabilidad comparando el ascorbato THD y el ácido L-ascórbico.</p>",
        id: 139,
        sendingClub: 160,
        options: null,
        img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749__1___1_-removebg-preview.png?v=1687426027",
      },
    ],
    hair: [
      {
        title: "Boombastic",
        summary:
          "Mascarilla capilar de nutrición intensa para recuperar tu cabello. Una explosión de activos fortalecedores para cuidar tus últimos 5 cm y prevenir la aparición de las puntas abiertas.",
        text: "Su fórmula única rica en Ácido Laúrico, Linoleico y Carotenoides, penetra en el tallo, previene la pérdida de proteína y fortalece la fibra capilar. Contiene componentes específicos hidrófugos que bloquean la humedad de la cutícula frenando el encrespamiento.<br><br> <li> Melena brillante, nutrida y poderosa</li><li> Hidratación y nutrición intensa</li><li> Repara el cabello dañado y seco</li><li> Controla el frizz</li><li> Testado Método Curly</li>",
        id: 53183,
        sendingClub: 53225,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_boombastic.png&width=400&height=600",
      },
      {
        title: "Savior",
        summary:
          "Último avance en innovación capilar a base de moléculas veganas que restauran y reparan la estructura molecular de la fibra capilar consiguiendo recuperar la salud y fortaleza de tu cabello (daño químico - tintes, físico - peinado, ambiental o térmico - planchas). EFICACIA CLINICA PROBADA.",
        text: "SAVIOR es la sinergia de 2 productos: FIXER con el que trabajamos y restauramos la fibra. Y SMOOTHER con el que recuperamos y renovamos la hidratación, flexibilidad y suavidad. Con los que conseguirás resultados visibles desde la primera aplicación.EFICACIA CLÍNICA PROBADA*:<li> 9 de cada 10 usuarias satisfechas*</li><li> Cabello recuperado en 4 aplicaciones en el 91%*</li><li> 96% recuperación del brillo natural*</li><li> 96% cabello más suave y flexible*</li><li> 87% cabello más hidratado, fuerte y resistente*</li>",
        id: 53239,
        sendingClub: 53274,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_savior_new.png&width=400&height=700",
      },
      {
        title: "Curly Method",
        summary:
          "Cocunat es conocida mundialmente por sus productos para rizos. Este pack reinventa el MÉTODO CURLY para hacerlo más sencillo, eficaz y apto para todas las rizadas. Solo 2 PASOS para definir, hidratar, nutrir y decir adiós al frizz definitivamente.",
        text: "Cada producto de este pack aporta aspectos específicos que se combinan para conseguir un rizo espectacular según tu tipo de cabello: El CURL MOISTURIZER LEAVE-IN desenreda, define e hidrata intensamente gracias a su especial formulación a base de Aceites de Argán, Jojoba y Abisinia que combaten la pérdida de agua y humedad. El CURL BOOSTER define y nutre el rizo sin apelmazarlo eliminando el efecto frizz debido a su fórmula enriquecida con Aceites de Coco, Argán, Pomelo, Mandarina, Naranja, Menta y Romero.<br><br><li> Defin</li><li> Nutre e hidrat</li><li> Elimina el Friz</li><li> Apto Curly Girl Method</li>",
        id: 53055,
        sendingClub: 53309,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_cmc.png&width=400&height=700",
      },
      {
        title: "Pack RIZOS DEFINICIÓN EXTRA CLUB",
        summary:
          "La combinación de productos definitiva para <strong> definir y fijar </strong>el cabello rizado sin dañar ni apelmazar el rizo. ¡Todo lo que necesitas en tu rutina para una melena <strong> rizada, definida, nutrida y sin frizz</strong>!",
        text: "<p>Los motivos por los cuales se convertirá en un imprescindible en tu rutina Curly:</p><p>Este pack es la sinergia perfecta para que tus rizos sean únicos, estén flexibles, sueltos, definidos, fijados y sin apelmazar. Está compuesto por:</p><p><strong>MÉTODO CURLY by COCUNAT y el CURL SPRAY</strong></p><p>El <strong>CURL MOISTURIZER LEAVE-IN</strong> <strong>desenreda, define</strong> e <strong>hidrata </strong>intensamente gracias a su especial formulación a base de<strong> Aceites de Argán, Jojoba</strong> y<strong> Abisinia</strong> que combaten la pérdida de agua y humedad.</p><p>El <strong>CURL BOOSTER</strong> <strong>define </strong>y <strong>nutre </strong>el rizo sin apelmazar eliminando el efecto <em>frizz </em>debido a su fórmula enriquecida con <strong>Aceites de Coco, Argán, Pomelo, Mandarina, Naranja, Menta</strong> y <strong>Romero</strong>.</p><p><strong>CURL SPRAY</strong> es un fijador natural y efectivo para aquellos cabellos que no solo quieran definir sus rizos con el Booster de rizos sino que además quieran <strong>potenciar la fijación</strong>. Es capaz de mantenerla a lo largo del día y sirve como <em>refresh</em> el día posterior al lavado.</p><ul><li>Rizos definidos y sueltos</li><li>SIN efecto apelmazado</li><li>Elimina el efecto <em>frizz</em></li><li>Combinación perfecta</li></ul>",
        id: 53110,
        sendingClub: 53348,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/pack-rizos-definicion-landing-club.jpg&width=400&height=700",
      },
    ],
    cnmu: [
      {
        title: "All in One",
        summary:
          "Paleta de maquillaje cruelty free y vegana con la combinación de pigmentos minerales perfecta para resaltar, sombrear y definir el rostro y la mirada. Cuenta con 3 sombras, 2 highlighters y 2 polvos faciales.",
        text: "Con esta paleta de edición especial, natural y vegana encontrarás todo lo necesario para conseguir un look completo para todas las ocasiones en tan solo 5 minutos. ALL-IN-ONE cuenta con 7 tonos de acabado aterciopelado y fáciles de aplicar con los que podrás crear looks ilimitados, desde el más natural y sutil hasta dramático y atrevido.<br><br><li> Resalta, sombrea y define</li><li> Resultado natural pero fantástico</li><li> 7 tonos en una sola paleta (cara y ojos)</li>",
        id: 53228,
        sendingClub: 53316,
        options: [
          {
            id: 53230,
            sendingClub: 53311,
            text: "Medium",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
          },
          {
            id: 53231,
            sendingClub: 53312,
            text: "Tan",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
          },
        ],
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_all_in_one.png&width=400&height=600",
      },
    ],
    body: [
      {
        title: "The glow",
        summary:
          "Crema corporal nutritiva con partículas minerales de efecto brillo para una piel firme, tersa, lisa y elástica. A base de aceite de Coco, Jojoba y Manteca de Karité aporta todos los nutrientes y vitaminas que nuestra piel necesita. ",
        text: "The Glow cuenta con un complejo natural exclusivo que estimula la producción de colágeno y elastina, así como neutraliza la acción de los radicales libres previniendo el envejecimiento y consiguiendo una piel firme y joven. Mejora la función protectora de la piel manteniendo la hidratación durante todo el día y nutriéndola intensamente por lo que es perfecto para estrías, embarazo o para pieles que necesitan un plus de nutrición.<br><br><li> Hidrata, nutre y reafirma la piel</li><li> Deja un reflejo dorado sublime</li><li> Aroma deliciosamente envolvente</li><li> Estimula la producción de colágeno y elastina</li><li> Rápida absorción</li>",
        id: 53176,
        sendingClub: 53278,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/glow_club.png&width=400&height=790",
      },
      {
        title: "The Extraordinary",
        summary:
          "Aceite corporal 3 en 1 a base de Aceites de Almendras, Rosa Mosqueta y Argán para conseguir que tu piel esté altamente hidratada, radiante y perfecta desde la primera aplicación. ",
        text: "Su estupendo aroma y su fácil aplicación hace que usarlo se convierta en un momento mágico. Además, tiene una importante acción preventiva y tratante contra las estrías; lo cual lo hace perfecto para embarazadas y cambios de peso.<br><br><li>Hidrata, nutre y regenera</li><li>Previene y trata las estrías</li><li>Reafima y deja la piel lisa</li><li>Perfecto durante el embarazo</li>",
        id: 52074,
        sendingClub: 53227,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_extraordinary.png&width=400&height=700",
      },
      {
        title: "Body Power",
        summary:
          "<p>BODY POWER llega para reinventar, innovar y revolucionar el concepto de los anticelul&iacute;ticos y reductores desde una perspectiva femenina, honesta, sincera y hol&iacute;stica.</p><p>BODY POWER es la sinergia de 2 grandes f&oacute;rmulas pensadas para trabajar en los dos momentos m&aacute;s importantes del d&iacute;a: Antes de tu rutina de ejercicio y mientras est&aacute;s en reposo. Resultados impresionantes en 56 d&iacute;as*.</p>",
        text: "Sus activos inteligentes cl&iacute;nicamente testados, como el Glucosyl Hesperidine, la Cafeina, el Fisetin &amp; Raspberry ketone, el Gingko Biloba o el &Aacute;cido hialur&oacute;nico (entre otros), trabajar&aacute;n los aspectos fundamentales para redefinir la silueta. Resultados impresionantes en 56 d&iacute;as*:<li>  Reduce la apariencia de la celulitis.</li><li> Reduce hasta 5,7 cm el per&iacute;metro de los muslos*</li><li> Hasta 5,5 cm el per&iacute;metro de las caderas*</li><li> Hasta 5 cm el per&iacute;metro del abdomen*</li><li> Hasta 2,4 cm el contorno del brazo*</li><li>*2 productos, ingredientes testados cl&iacute;nicamente en 60 mujeres y resultados visibles en 56 d&iacute;as.</li>        ",
        id: 53299,
        sendingClub: 53344,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1649402974082_render-body-power.jpg&width=400&height=700",
      },
    ],
  },
  en: {
    face: [
      {
        title: "The Absolute",
        summary:
          "The Anti-Aging Cream with innovative natural technology that controls the aging processes of GEN NC886 to regenerate and rejuvenate the skin",
        text: "Low molecular weight Hyaluronic Acid plus its 12 biologically active components effectively fight aging, neutralize free radicals and plump wrinkles. An explosion of nutrients, vitamins and proteins that stimulate collagen production, regenerating cells and resulting in a supple, smooth and blemish-free skin.<br><br><li>Restructures all skin types from the inside out</li><li>Stimulates collagen production and skin regeneration.</li><li>Reduces nasolabial wrinkles by 19%.</li><li>Increases skin nutrition by 14%.</li><li>Reduces blemishes by 10%</li>",
        id: 53123,
        sendingClub: 53275,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_absolute.png&width=400&height=700",
      },
      {
        title: "The Cure",
        summary:
          "Ultimate skin-healing serum for any age. Transform your skin with 21 unique 100% natural oils and Bakuchiol to repair and treat the deepest wrinkles.",
        text: "4 keys to turn it into an addiction:<p><strong>BAKUCHIOL</strong>: The natural alternative to retinol that stimulates collagen and elastin production, inhibits melanin production and repairs, redensifies and treats even the deepest wrinkles </p><p><strong> VITAMIN EXPLOSION</strong>: Combined action of vitamins B, C, E and K with quickly visible results. Provides radiance and unifies the skin for an 8h sleep effect.</p><p><strong>PRODIGIOUS ENZYME</strong>: Superoxide Dismutase is a cellular renewer that will completely change your skin. It neutralizes free radicals and reverses the signs of aging.</p><p><strong>BOTOX VEGETAL + FATTY ACIDS</strong>: A unique synergy to rejuvenate your skin. Increases firmness by smoothing deep and superficial wrinkles. </p><li>Dazzling skin</li><li>Fills wrinkles</li><li>Stimulates collagen and hyaluronic acid formation</li>",
        id: 53252,
        sendingClub: 53224,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_cure.png&width=400&height=700",
      },
      {
        title: "Wondermask",
        summary:
          "5-in-1 face mask for flawless skin without the need for filters. Detoxifies, balances, refines pores, exfoliates and fights the effects of pollution",
        text: "Mask created from natural complexes: Prickly Pear, Rosa Canina, Chamomile, Sunflower, Ginger and the power of our pure Mediterranean clay will make your skin perfect in just a few minutes getting a smooth and clean skin. <li>Detoxifies and balances the skin</li><li>Closes and refines pores</li><li>Eliminates the appearance of a puffy face </li><li>Brightens and evens skin tone</li><li>Protects against blue light from screens</li><li>Revitalizes and remineralizes the skin</li>",
        id: 53102,
        sendingClub: 53226,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_wondermask.png&width=400&height=700",
      },
      {
        title: "THE REAL C ",
        summary:
          "The most powerful anti-aging shield with 100% Pure Vitamin C (Ascorbic Acid) at 20%. Up to 178 times more potent than Vitamin C derivatives*, it works on loose, blemished, wrinkled and dull skin. EcoRecharge format	<br><small>*After stability tests comparing ascorbate THD and L-ascorbic acid.</small>",
        text: "<p>THE REAL C is a complete treatment to give luminosity and elasticity to the skin, reduce wrinkles and spots thanks to the purest Vitamin C. Now in 30 days format: contains 30 biodegradable single-dose pearls packaged in an oxygen-free atmosphere with a 20% concentration of pure 100% Vitamin C (Ascorbic Acid).</p><p>This supercosmetic, up to 178 times more potent than Vitamin C derivatives* together with Vitamin E, penetrates into the deepest layers of skin, boosting collagen synthesis. This synergy creates an antioxidant complex that acts as a shield against free radicals: protecting cells from damage, improving wrinkles and the firmness of facial contours, boosting elasticity and visibly fading dark spots.</p><p>Proven clinical results after 8 weeks:</p><li>Increases skin firmness and elasticity by 6.7%</li><li>Improves the skin’s brightness by 15.1%</li><li>Diminishes the intensity of color and the size of dark spots by up to 22.9%</li><li>Reduces the depth of wrinkles by up to 22.2%</li><li>Treatment for 4 weeks</li><p>*After conducting stability tests comparing THD ascorbate with L-ascorbic acid.</p>",
        id: 139,
        sendingClub: 160,
        options: null,
        img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749__1___1_-removebg-preview.png?v=1687426027",
      },
    ],
    hair: [
      {
        title: "Boombastic",
        summary:
          "Intense nourishing hair mask to restore your hair. An explosion of strengthening active ingredients to take care of your last 5 cm and prevent the appearance of split ends.",
        text: "Its unique formula rich in Lauric Acid, Linoleic Acid and Carotenoids, penetrates the hair shaft, prevents protein loss and strengthens the hair fiber. Contains specific water-repellent components that block moisture from the cuticle and stop frizz.<br><br><li>Shiny, nourished and powerful hair.</li><li>Intense hydration and nourishment</li><li>Repairs damaged and dry hair</li><li>Controls frizz</li><li>Tested Curly Method.</li>",
        id: 53183,
        sendingClub: 53225,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_boombastic.png&width=400&height=600",
      },
      {
        title: "Savior",
        summary:
          "Latest breakthrough in scientific hair innovation based on vegan molecules that restore and repair the molecular structure of the hair fiber to recover the health and strength of your hair (chemical damage - dyes, physical - styling, environmental or thermal - irons). PROVEN CLINICAL EFFICACY.",
        text: "SAVIOR is the synergy of 2 products: FIXER with which we treat and restore the fiber. And SMOOTHER with which we recover and renew hydration, flexibility and softness. With which you will achieve visible results from the first application.PROVEN CLINICAL EFFICACY*:<li>9 out of 10 satisfied users *.</li><li>91% of hair regained in 4 applications*.</li><li>96% recovery of natural shine</li><li>96% softer and more flexible hair* 96% softer and more flexible hair* 87% more moisturized hair* 87% more moisturized hair</li><li>87% hair more hydrated, stronger and more resistant * 87% hair more hydrated, stronger and more resistant*</li>",
        id: 53239,
        sendingClub: 53274,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_savior_new.png&width=400&height=700",
      },
      {
        title: "Curly Method",
        summary:
          "Cocunat is known worldwide for its products for curls. This pack reinvents the CURLY METHOD to make it simpler, more effective and suitable for all curly hair. Just 2 STEPS to define, moisturize, nourish and say goodbye to frizz for good.",
        text: "Each product in this pack provides specific aspects that combine to achieve a spectacular curl according to your hair type: CURL MOISTURIZER LEAVE-IN detangles, defines and intensely moisturizes thanks to its special formulation based on Argan, Jojoba and Abyssinia Oils that combat water and moisture loss. The CURL BOOSTER defines and nourishes curls without weighing them down, eliminating the frizz effect thanks to its formula enriched with Coconut, Argan, Grapefruit, Mandarin, Orange, Mint and Rosemary Oils. <li>Define</li><li>Nourishes and moisturizes</li><li>Eliminates Frizz</li><li>Suitable for Curly Girl Method</li>",
        id: 53055,
        sendingClub: 53309,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_cmc.png&width=400&height=700",
      },
      {
        title: "EXTRA DEFINED CURLS Plan CLUB",
        summary:
          "<p>The definitive combination of products to <strong>define </strong>and <strong>hold </strong>the curl in place without damaging the hair or making it flat. It's all you need in your routine for<strong> curly, defined and nourished locks with no<em> frizz</em>!</strong></p>",
        text: "<p>The reasons why it will become a must-have in your Curly routine:</p><p>This pack is the perfect synergy to make your curls unique, flexible, loose, defined, fixed and without weighing them down. It consists of:</p><p><strong>CURLY METHOD by COCUNAT and the CURL SPRAY</strong></p><p>The <strong>LEAVE-IN CURL MOISTURIZER</strong> <strong>untangles, defines</strong> and deeply <strong>hydrates </strong>thanks to its special formula with <strong>Argan, Jojoba</strong> and<strong> Abyssinia oils</strong> which combat water and humidity loss.</p><p>The <strong>CURL BOOSTER</strong> <strong>defines </strong>and <strong>nourishes </strong>the curl without flattening it, getting rid of any <em>frizz </em>thanks to its formula enriched with <strong>Coconut, Argan, Grapefruit, Mandarin, Orange, Mint</strong> and <strong>Rosemary oils</strong>.</p><p><strong>CURL SPRAY</strong> is a natural and effective hairspray for those who not only want to define their curls with the Curl Booster but also want to <strong>keep the hold</strong>. It keeps curls in place throughout the day and acts as a <em>refresh</em> the day after washing your hair.</p><ul><li>Defined, loose curls</li><li>It does not flatten the hair</li><li>NO <em>frizz</em></li><li>The perfect combination</li></ul>",
        id: 53110,
        sendingClub: 53348,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/pack-rizos-definicion-landing-club.jpg&width=400&height=700",
      },
    ],
    cnmu: [
      {
        title: "All in One",
        summary:
          "A cruelty free and vegan makeup palette with the perfect combination of mineral pigments to highlight, shade and define the face and eyes. It has 3 shadows, 2 highlighters and 2 face powders.            ",
        text: "With this special edition, natural and vegan palette you'll find everything you need to achieve a complete look for all occasions in just 5 minutes. ALL-IN-ONE features 7 velvety-finish, easy-to-apply shades with which you can create unlimited looks, from natural and subtle to dramatic and bold. <li>Highlight, shade and define</li><li>Natural yet fantastic result</li><li>7 shades in one palette (face and eyes)</li>",
        id: 53228,
        sendingClub: 53316,
        options: [
          {
            id: 53229,
            sendingClub: 53310,
            text: "Light",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
          },
          {
            id: 53230,
            sendingClub: 53311,
            text: "Medium",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
          },
          {
            id: 53231,
            sendingClub: 53312,
            text: "Tan",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
          },
        ],
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_all_in_one.png&width=400&height=600",
      },
    ],
    body: [
      {
        title: "The glow",
        summary:
          "Nourishing body cream with shimmering mineral particles for a firm, smooth, soft and elastic skin.  The Glow -thanks to its exclusive combination of Coconut Oil, Jojoba and Shea Butter provides all the nutrients, moisturizers and vitamins our skin needs.",
        text: "The Glow has an exclusive natural complex that stimulates the production of collagen and elastin, as well as neutralizes the action of free radicals preventing aging and achieving a firm and youthful skin. Improves the protective function of the skin by maintaining hydration throughout the day and intensely nourishing it, making it perfect for stretch marks, pregnancy or for skin that needs extra nutrition.<br><br><li>Moisturizes, nourishes and firms the skin.</li><li>Leaves a sublime golden glow</li><li>Deliciously enveloping aroma</li><li>Stimulates collagen and elastin production</li><li>Rapid absorption</li>",
        id: 53176,
        sendingClub: 53278,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/glow_club.png&width=400&height=790",
      },
      {
        title: "The Extraordinary",
        summary:
          "3 in 1 body oil based on Almond, Rosehip and Argan Oils to get your skin highly hydrated, radiant and perfect from the first application. ",
        text: "Its great scent and easy application makes using it a magical moment. In addition, it has an important preventive and treating action against stretch marks; which makes it perfect for pregnant women and weight changes.<br><br><li>Moisturizes, nourishes and regenerates.</li><li>Prevents and treats stretch marks</li><li>Reaffirms and leaves the skin smooth</li><li>Perfect during pregnancy</li>",
        id: 52074,
        sendingClub: 53227,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_extraordinary.png&width=400&height=700",
      },
      {
        title: "Body Power",
        summary:
          "<p>BODY POWER is here to reinvent, innovate and revolutionize the concept of anti-cellulite and slimming products from a feminine, honest, sincere and holistic perspective. Two great formulas designed to work at the two most important times of your day: before your exercise routine and while you are at rest. Results in 56 days*</p>",
        text: "Their clinically tested intelligent active ingredients, such as Glucosyl Hesperidine, Caffeine, Fisetin &amp; Raspberry ketone, Gingko Biloba or Hyaluronic Acid (among others), will work on key aspects to shape your body. The results are impressive (results in 56 days*):<li> Reduces the appearance of cellulite.</li><li> Reduces Up to 5.7 cm thigh circumference*.</li><li> Up to 5.5 cm hip circumference*.</li><li> Up to 5 cm abdomen circumference*</li><li> Up to 2.4 cm arm circumference*.</li><li>*2 products, clinically tested ingredients on 60 women and visible results in 56 days.</li>        ",
        id: 53299,
        sendingClub: 53344,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1649402974082_render-body-power.jpg&width=400&height=700",
      },
    ],
  },
  fr: {
    face: [
      {
        title: "The Absolute",
        summary:
          "La crème anti-âge avec une technologie naturelle innovante qui contrôle les processus de vieillissement du GEN NC886 pour régénérer et rajeunir la peau",
        text: "L'acide hyaluronique de faible poids moléculaire et ses 12 ingrédients biologiquement actifs luttent efficacement contre le vieillissement, neutralisent les radicaux libres et comblent les rides. Une explosion de nutriments, de vitamines et de protéines qui stimulent la production de collagène, régénèrent les cellules et donnent une peau souple, lisse et sans tâches.<br><br><li>Restructure tous les types de peau de l'intérieur</li><li>Stimule la production de collagène et la régénération de la peau</li><li>Réduit les rides nasogéniennes de 19 %</li><li>Augmente la nutrition de la peau de 14%</li><li>Réduit les tâches de 10%</li>",
        id: 53123,
        sendingClub: 53275,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_absolute.png&width=400&height=700",
      },
      {
        title: "The Cure",
        summary:
          "Le sérum ultime pour soigner la peau à tout âge. Transformez votre peau avec 21 huiles uniques 100% naturelles et du Bakuchiol pour réparer et traiter les rides les plus profondes",
        text: "4 clés pour en faire une addiction :<p><strong>BAKUCHIOL</strong> : L'alternative naturelle au rétinol qui stimule la production de collagène et d'élastine, inhibe la production de mélanine et répare, redensifie et traite les rides les plus profondes.</p><p><strong>EXPLOSION DE VITAMINES </strong>: Action combinée des vitamines B, C, E et K avec des résultats rapidement visibles. Éclaircit et unifie la peau pour un effet sommeil de 8 heures.</p><p><strong>ENZYME PRODIGIEUX </strong>: La Superoxyde Dismutase est un rénovateur cellulaire qui va complètement changer votre peau. Il neutralise les radicaux libres et inverse les signes du vieillissement.</p><p><strong>BOTOX VEGETAL + ACIDES GRAS</strong> : Une synergie unique pour rajeunir votre peau. Augmente la fermeté en lissant les rides profondes et superficielles. </p><li>Une peau éblouissante</li><li>Comble les rides</li><li>Stimule la formation de collagène et d'acide hyaluronique</li>",
        id: 53252,
        sendingClub: 53224,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_cure.png&width=400&height=700",
      },
      {
        title: "Wondermask",
        summary:
          "Masque facial 5 en 1 pour une peau parfaite sans avoir besoin de filtres. Détoxifie, équilibre, affine les pores, exfolie et combat les effets de la pollution.",
        text: "Le masque créé à partir de complexes naturels : figue de barbarie, Rosa Canina, camomille, tournesol, gingembre et la puissance de notre argile méditerranéenne pure rendra votre peau parfaite en quelques minutes, obtenant une peau lisse et propre. <li>Détoxifie et équilibre la peau</li><li>Resserre et affine les pores</li><li>Élimine l'apparence d'un visage bouffi </li><li>Éclaircit et unifie le teint de la peau</li><li>Protège contre la lumière bleue des écrans</li><li>Revitalise et reminéralise la peau</li>",
        id: 53102,
        sendingClub: 53226,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_wondermask.png&width=400&height=700",
      },
      {
        title: "THE REAL C ",
        summary:
          "Le bouclier anti-âge le plus puissant avec de la vitamine C (acide ascorbique) 100% pure à 20%. Jusqu'à 178 fois plus puissant que les dérivés de la vitamine C*, il agit sur le relâchement, les imperfections, les rides et les peaux ternes. Format EcoRecharge<br><small>*Après des tests de stabilité comparant l'ascorbate THD et l'acide L-ascorbique.</small>",
        text: "<p>Ce produit cosmétique extraordinaire, jusqu’à 178 fois plus puissant que les dérivés* de la vitamine C, associé à la vitamine E, pénètre dans les couches les plus profondes de la peau pour améliorer la synthèse de collagène. Cette synergie forme un complexe antioxydant qui fait office de bouclier contre les radicaux libres : protégeant contre les dommages cellulaires, agissant sur les rides et raffermissant le contour du visage, stimulant la souplesse et réduisant visiblement les taches brunes.</p><p>Résultats cliniquement prouvés après 8 semaines :</p><li>Augmente la fermeté et l’élasticité de la peau jusqu’à 6,7 %</li><li>Améliore l’éclat de la peau jusqu’à 15,1 %</li><li>Atténue l’intensité de la couleur et la taille des taches brunes jusqu’à 22,9 %</li><li>Réduit la profondeur des rides jusqu’à 22,2 %</li>Traitement pour 4 semaines<p>*Après réalisation de tests de stabilité comparant l’ascorbate THD et l’acide L-ascorbique.</p>",
        id: 139,
        sendingClub: 160,
        options: null,
        img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749__1___1_-removebg-preview.png?v=1687426027",
      },
    ],
    hair: [
      {
        title: "Boombastic",
        summary:
          "Masque capillaire nourrissant intense pour restaurer vos cheveux. Une explosion de principes actifs fortifiants pour prendre soin de vos 5 derniers centimètres et prévenir l'apparition des pointes fourchues.",
        text: "Sa formule unique, riche en acide laurique, en acide linoléique et en caroténoïdes, pénètre dans la tige du cheveu, prévient la perte de protéines et renforce la fibre capillaire. Contient des composants hydrofuges spécifiques qui bloquent l'humidité de la cuticule, stoppant ainsi les frisottis.Des cheveux brillants, nourris et puissants.<br><br><li>Hydratation et nutrition intenses</li><li>Répare les cheveux endommagés et secs</li><li>Contrôle les frisottis</li><li>Convient à la Méthode Curly.</li>",
        id: 53183,
        sendingClub: 53225,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_boombastic.png&width=400&height=600",
      },
      {
        title: "Savior",
        summary:
          "Dernière avancée dans l'innovation scientifique capillaire basée sur des molécules végétaliennes qui restaurent et réparent la structure moléculaire de la fibre capillaire, rétablissant ainsi la santé et la force de vos cheveux (dommages chimiques <li>teintures, physiques <li>coiffage, environnementaux ou thermiques <li>fers à repasser). EFFICACITÉ CLINIQUE PROUVÉE.</li>",
        text: "SAVIOR est la synergie de 2 produits : FIXER avec lequel nous travaillons et restaurons la fibre. Et SMOOTHER avec lequel nous retrouvons et renouvelons l'hydratation, la souplesse et la douceur. Avec lequel vous obtiendrez des résultats visibles dès la première application.EFFICACITÉ CLINIQUE PROUVÉE* :<li>9 utilisateurs satisfaits sur 10 *.</li><li>91% de cheveux retrouvés en 4 applications*</li><li>96% de récupération de la brillance naturelle* </li><li>96% de cheveux plus doux et plus souples* </li><li>87% de cheveux plus hydratés, plus forts et plus résistants *</li>",
        id: 53239,
        sendingClub: 53274,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_savior_new.png&width=400&height=700",
      },
      {
        title: "Curly Method",
        summary:
          "Cocunat est connu dans le monde entier pour ses produits de boucles. Ce pack réinvente la MÉTHODE CURLY pour la rendre plus simple, plus efficace et adaptée à tous les cheveux bouclés. Il suffit de 2 étapes pour définir, hydrater, nourrir et dire adieu aux frisottis pour de bon.",
        text: "Chaque produit de ce pack offre des aspects spécifiques qui se combinent pour obtenir une boucle spectaculaire en fonction de votre type de cheveux : CURL MOISTURIZER LEAVE-IN démêle, définit et hydrate intensément grâce à sa formulation spéciale à base d'huiles d'Argan, de Jojoba et d'Abyssinie qui combattent la perte d'eau et d'hydratation. Le CURL BOOSTER définit et nourrit les boucles sans les alourdir, en éliminant l'effet frisottis grâce à sa formule enrichie en huiles de coco, d'argan, de pamplemousse, de mandarine, d'orange, de menthe et de romarin.<br><br><li>Définit</li><li>Nourrit et hydrate</li><li>Élimine les frisottis</li><li>Convient à la Méthode Curly</li>",
        id: 53055,
        sendingClub: 53309,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_cmc.png&width=400&height=700",
      },
      {
        title: "Pack BOUCLES DÉFINITION EXTRA CLUB",
        summary:
          "<p>L'association définitive de produits pour <strong>définir </strong>et <strong>fixer </strong>les cheveux bouclés, sans endommager ni aplatir les boucles. Tout ce qu'il vous faut dans votre routine pour<strong> une chevelure bouclée, définie, nourrie et sans<em> frisottis </em>!</strong></p>",
        text: "<p>Les raisons pour lesquelles il va devenir un incontournable dans votre routine curly :</p><p>Ce pack est la synergie parfaite pour obtenir des boucles uniques, souples, libres, définies, fixées et sans les aplatir. Il se compose de :</p><p><strong>MÉTHODE CURLY by COCUNAT et CURL SPRAY</strong></p><p>Le <strong>CURL MOISTURIZER LEAVE-IN</strong> <strong>démêle, définit</strong> et <strong>hydrate </strong>intensément grâce à sa formule spéciale à base d'<strong>huiles d'argan, de jojoba</strong> et<strong> d'Abyssinie</strong> qui luttent contre la perte d'eau et d'humidité.</p><p>Le <strong>CURL BOOSTER</strong> <strong>définit </strong>et <strong>nourrit </strong>les boucles sans les aplatir en éliminant l'effet <em>frisottis, </em>grâce à sa formule enrichie aux <strong>huiles de noix de coco, d'argan, de pamplemousse, de mandarine, d'orange, de menthe</strong> et de <strong>romarin</strong>.</p><p><strong>CURL SPRAY</strong> est un fixateur naturel et efficace pour les cheveux qui ont besoin de définir leurs boucles avec le booster de boucles, mais qui doivent également <strong>renforcer leur fixation</strong>. il est capable de la maintenir tout au long de la journée et fait office de <em>refresh</em> le jour après le lavage.</p><ul><li>Des boucles définies et libres</li><li>SANS effet aplati</li><li>Élimine l'effet <em>frisottis</em></li><li>Un combo parfait</li></ul>",
        id: 53110,
        sendingClub: 53348,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/pack-rizos-definicion-landing-club.jpg&width=400&height=700",
      },
    ],
    cnmu: [
      {
        title: "All in One",
        summary:
          "Cette palette de maquillage végétalienne et sans cruauté offre une combinaison parfaite de pigments minéraux pour mettre en valeur, ombrer et définir le visage et les yeux. Il contient 3 ombres, 2 highlighters et 2 poudres pour le visage.",
        text: "Avec cette palette en édition spéciale, naturelle et végane, vous trouverez tout ce dont vous avez besoin pour réaliser un look complet pour toutes les occasions en seulement 5 minutes. ALL-IN-ONE comprend 7 nuances veloutées et faciles à appliquer pour créer des looks illimités, du naturel et subtil au spectaculaire et audacieux. <li>Souligne, ombrage et définit</li><li>Des résultats naturels mais fantastiques</li><li>7 teintes en une seule palette (visage et yeux)</li>",
        id: 53228,
        sendingClub: 53316,
        options: [
          {
            id: 53229,
            sendingClub: 53310,
            text: "Light",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
          },
          {
            id: 53230,
            sendingClub: 53311,
            text: "Medium",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
          },
          {
            id: 53231,
            sendingClub: 53312,
            text: "Tan",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
          },
        ],
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_all_in_one.png&width=400&height=600",
      },
    ],
    body: [
      {
        title: "The glow",
        summary:
          "Crème corporelle nutritive aux particules minérales scintillantes pour une peau ferme, lisse, douce et souple.  The Glow, grâce à sa combinaison exclusive d'huile de coco, de jojoba et de beurre de karité, apporte tous les nutriments, les agents hydratants et les vitamines dont notre peau a besoin. ",
        text: "The Glow contient un complexe naturel exclusif qui stimule la production de collagène et d'élastine, et neutralise l'action des radicaux libres. Il prévient le vieillissement et permet d'obtenir une peau ferme et jeune. Il améliore la fonction protectrice de la peau en maintenant l'hydratation tout au long de la journée et en la nourrissant intensément. Il est donc parfait pour les vergetures, la grossesse ou les peaux qui ont besoin d'une alimentation supplémentaire.<br><br><li>Hydrate, nourrit et raffermit la peau.</li><li>Laisse un sublime éclat doré</li><li>Un arôme délicieusement enveloppant</li><li>Stimule la production de collagène et d'élastine</li><li>Absorption rapide</li>",
        id: 53176,
        sendingClub: 53278,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/glow_club.png&width=400&height=790",
      },
      {
        title: "The Extraordinary",
        summary:
          "Huile corporelle 3 en 1 à base d'huiles d'amande, de rose musquée et d'argan pour rendre votre peau hautement hydratée, radieuse et parfaite dès la première application.",
        text: "Son parfum agréable et son application facile font de son utilisation un moment magique. En plus, il a une importante action préventive et traitante contre les vergetures ; ce qui le rend parfait pour les femmes enceintes et les changements de poids.<br><br><li>Hydrate, nourrit et régénère.</li><li>Prévient et traite les vergetures</li><li>Raffermit et lisse la peau</li><li>Parfait pendant la grossesse</li>",
        id: 52074,
        sendingClub: 53227,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_extraordinary.png&width=400&height=700",
      },
      {
        title: "Body Power",
        summary:
          "<p>BODY POWER vient r&eacute;inventer, innover et r&eacute;volutionner le concept des produits anticellulite et amincissants dans une perspective f&eacute;minine, honn&ecirc;te, sinc&egrave;re et holistique. Deux grandes formules con&ccedil;ues pour agir aux deux moments les plus importants de la journ&eacute;e : avant votre programme d'exercice et pendant votre repos. R&eacute;sultats en 56 jours*.</p>",
        text: "Leurs principes actifs intelligents test&eacute;s cliniquement, tels que la Glucosyl Hesperidine, la Caf&eacute;ine, la Fisetin &amp; Raspberry ketone, le Gingko Biloba ou l'Acide Hyaluronique (entre autres), travaillent sur les aspects fondamentaux pour red&eacute;finir votre silhouette (r&eacute;sultats en 56 jours*):<li> R&eacute;duit l'apparence de la cellulite.</li><li> R&eacute;duit jusqu'&agrave; 5,7 cm de tour de cuisse*.</li><li> R&eacute;duit jusqu'&agrave; 5,5 cm de tour de hanches*.</li><li> R&eacute;duit jusqu'&agrave; 5 cm de tour d'abdomen*</li><li> R&eacute;duit jusqu'&agrave; 2,4 cm de tour de bras*</li><li> *2 produits, ingr&eacute;dients test&eacute;s cliniquement sur 60 femmes et r&eacute;sultats visibles en 56 jours.</li>",
        id: 53299,
        sendingClub: 53344,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1649402974082_render-body-power.jpg&width=400&height=700",
      },
    ],
  },
  it: {
    face: [
      {
        title: "The Absolute",
        summary:
          "La crema anti-invecchiamento con un'innovativa tecnologia naturale che controlla i processi di invecchiamento GEN NC886 per rigenerare e ringiovanire la pelle",
        text: "L'acido ialuronico a basso peso molecolare e i suoi 12 componenti biologicamente attivi combattono efficacemente l'invecchiamento, neutralizzano i radicali liberi e riempiono le rughe. Un'esplosione di nutrienti, vitamine e proteine che stimolano la produzione di collagene, rigenerando le cellule e ottenendo una pelle elastica, liscia e senza macchie.<br><br><li>Ristruttura tutti i tipi di pelle dall'interno verso l'esterno</li><li>Stimola la produzione di collagene e la rigenerazione della pelle.</li><li>Riduce le rughe nasolabiali del 19%.</li><li>Aumenta la nutrizione della pelle del 14%.</li><li>Riduce le macchie del 10%.</li>",
        id: 53123,
        sendingClub: 53275,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_absolute.png&width=400&height=700",
      },
      {
        title: "The Cure",
        summary:
          "Il siero definitivo per guarire la pelle a qualsiasi età. Trasforma la tua pelle con 21 oli unici 100% naturali e Bakuchiol per riparare e trattare le rughe più profonde",
        text: "4 chiavi per trasformarlo in una dipendenza:<p><strong>BAKUCHIOL</strong>: L'alternativa naturale al retinolo che stimola la produzione di collagene ed elastina, inibisce la produzione di melanina e ripara, ridensifica e tratta anche le rughe più profonde.</p><p><strong>ESPLOSIONE DI VITAMINE</strong>: Azione combinata di vitamine B, C, E e K con risultati rapidamente visibili. Illumina e uniforma la pelle per un effetto sonno di 8 ore.</p><p><strong>ENZIMA PRODIGIO</strong>: La Superossido Dismutasi è un rinnovatore cellulare che cambierà completamente la tua pelle. Neutralizza i radicali liberi e inverte i segni dell'invecchiamento.</p><p><strong>BOTOX VEGETAL + ACIDI GRASSI</strong>: Una sinergia unica per ringiovanire la pelle. Aumenta la fermezza levigando le rughe profonde e superficiali.</p> <li>Pelle abbagliante</li><li>Riempie le rughe</li><li>Stimola la formazione di collagene e acido ialuronico</li>",
        id: 53252,
        sendingClub: 53224,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_cure.png&width=400&height=700",
      },
      {
        title: "Wondermask",
        summary:
          "Maschera per il viso 5 in 1 per una pelle perfetta senza bisogno di filtri. Disintossica, equilibra, affina i pori, esfolia e combatte gli effetti dell'inquinamento.",
        text: "Maschera creata con complessi naturali: Fico d'India, Rosa Canina, Camomilla, Girasole, Zenzero e il potere della nostra argilla mediterranea pura renderà la vostra pelle perfetta in pochi minuti ottenendo una pelle liscia e pulita. <li>Disintossica e bilancia la pelle</li><li>Stringe e affina i pori</li><li>Elimina l'aspetto di un viso gonfio </li><li>Illumina e uniforma il tono della pelle</li><li>Protegge dalla luce blu degli schermi</li><li>Rivitalizza e rimineralizza la pelle.</li>",
        id: 53102,
        sendingClub: 53226,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_wondermask.png&width=400&height=700",
      },
      {
        title: "THE REAL C ",
        summary:
          "Il più potente scudo anti-età con vitamina C pura al 100% (acido ascorbico) al 20%. Fino a 178 volte più potente dei derivati della vitamina C*, agisce su segni di rilassamento, macchie, rughe e pelle spenta. Formato EcoRicarica <br><small>Dopo i test di stabilità che hanno messo a confronto l'ascorbato THD e l'acido L-ascorbico.</small>	",
        text: "<p>Questo super-cosmetico fino a 178 volte più potente dei derivati* della vitamina C e accompagnato dalla vitamina E, penetra negli strati cutanei più profondi, migliorando la sintesi del collagene. Tale sinergia forma un complesso antiossidante che funge da scudo contro i radicali liberi: protegge dai danni cellulari, agisce sulle rughe e ridefinisce l’ovale del viso, potenzia l’elasticità e riduce visibilmente le macchie.</p><p>Risultati clinici comprovati dopo 8 settimane:</p><li>Aumenta la compattezza e l'elasticità della pelle fino al 6,7%</li><li>Migliora la luminosità della pelle fino al 15,1%</li><li>Riduce l’intensità del colore e le dimensioni delle macchie scure fino al 22,9%</li><li>Riduce la profondità delle rughe fino al 22,2%</li><li>Trattamento di 4 settimane</li><p>*In seguito a test di stabilità che confrontano l'ascorbato THD e l'acido L-ascorbico.</p>",
        id: 139,
        sendingClub: 160,
        options: null,
        img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749__1___1_-removebg-preview.png?v=1687426027",
      },
    ],
    hair: [
      {
        title: "Boombastic",
        summary:
          "Maschera nutriente intensa per ripristinare i tuoi capelli. Un'esplosione di principi attivi rinforzanti per curare i tuoi ultimi 5 cm e prevenire la comparsa delle doppie punte.            ",
        text: "La sua formula unica, ricca di acido laurico, acido linoleico e carotenoidi, penetra nel fusto, previene la perdita di proteine e rafforza la fibra capillare. Contiene componenti specifici idrorepellenti che bloccano l'umidità dalla cuticola, fermando l'effetto crespo.<br><br><li>Capelli lucidi, nutriti e potenti.</li><li>Idratazione e nutrimento intensi</li><li>Ripara i capelli danneggiati e secchi</li><li>Controlla l'effetto crespo</li><li>Metodo riccio testato.</li>",
        id: 53183,
        sendingClub: 53225,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_boombastic.png&width=400&height=600",
      },
      {
        title: "Savior",
        summary:
          "Ultimo progresso nell'innovazione scientifica dei capelli basata su molecole vegane che restaurano e riparano la struttura molecolare della fibra capillare, ripristinando la salute e la forza dei tuoi capelli (danni chimici - tinture, fisici - styling, ambientali o termici - ferri). DI COMPROVATA EFFICACIA CLINICA.",
        text: "SAVIOR è la sinergia di 2 prodotti: FIXER con cui lavoriamo e ripristiniamo la fibra. E SMOOTHER con cui recuperiamo e rinnoviamo l'idratazione, la flessibilità e la morbidezza. Con il quale otterrete risultati visibili fin dalla prima applicazione.EFFICACIA CLINICA PROVATA*:<li>9 utenti soddisfatti su 10 *.</li><li>91% di capelli riconquistati in 4 applicazioni*.</li><li>96% di recupero della lucentezza naturale* 96% di recupero della lucentezza naturale* 96% di <li>recupero della lucentezza naturale</li><li>96% di capelli più morbidi e flessibili* 96% di capelli più morbidi e flessibili</li><li>87% di capelli più idratati, più forti e più resistenti*.</li>",
        id: 53239,
        sendingClub: 53274,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_savior_new.png&width=400&height=700",
      },
      {
        title: "Curly Method",
        summary:
          "Cocunat è conosciuto in tutto il mondo per i suoi prodotti per riccioli. Questo pacchetto reinventa il CURLY METHOD per renderlo più semplice, più efficace e adatto a tutti i capelli ricci. Solo 2 PASSI per definire, idratare, nutrire e dire addio al crespo per sempre.            ",
        text: "Ogni prodotto in questa confezione fornisce aspetti specifici che si combinano per ottenere un riccio spettacolare secondo il tuo tipo di capelli: CURL MOISTURIZER LEAVE-IN districa, definisce e idrata intensamente grazie alla sua speciale formulazione a base di oli di Argan, Jojoba e Abyssinia che combattono la perdita di acqua e idratazione. Il CURL BOOSTER definisce e nutre i ricci senza appesantirli, eliminando l'effetto crespo grazie alla sua formula arricchita con oli di cocco, argan, pompelmo, mandarino, arancia, menta e rosmarino.<br><br><li>Definire</li><li>Nutre e idrata</li><li>Elimina l'effetto crespo</li><li>Adatto al metodo della ragazza riccia.</li>",
        id: 53055,
        sendingClub: 53309,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_cmc.png&width=400&height=700",
      },
      {
        title: "Pack RICCI EXTRA  DEFINIZIONE CLUB",
        summary:
          "<p>La combinazione di prodotti definitiva per <strong>definire </strong>e <strong>fissare </strong>i capelli ricci senza danneggiare o appesantire il riccio. Tutto ciò di cui hai bisogno nella tua routine per<strong> capelli ricci, definiti, nutriti senza effetto<em> crespo</em>!</strong></p>",
        text: "<p>I motivi per cui diventerà un must nella tua routine Curly:</p><p>Questo pack è la sinergia perfetta per rendere i tuoi ricci unici, flessibili, sciolti, definiti, riparati e non appesantiti. È composto da:</p><p><strong>METODO CURLY by COCUNAT e il CURL SPRAY</strong></p><p>Il <strong>CURL MOISTURIZER LEAVE-IN</strong> <strong>districa, definisce</strong> e <strong>idrata </strong>intensamente grazie alla sua speciale formula basata su<strong> Olio di Argan, Jojoba</strong> e<strong> Abissinia</strong> che combattono la perdita di acqua e umidità.</p><p>Il <strong>CURL BOOSTER</strong> <strong>definisce </strong>e <strong>nutre </strong>il riccio senza appesantire eliminando l'effetto <em>crespo </em>grazie alla sua formula ricca di <strong>Olio di Cocco, Argan, Pompelmo, Mandarino, Arancia, Menta</strong> e <strong>Rosmarino</strong>.</p><p><strong>CURL SPRAY</strong> è un fissativo naturale ed efficace per quei capelli che non solo vogliono definire i loro ricci con il Curl Booster ma vogliono anche <strong>potenziare il fissaggio</strong>. È in grado di mantenerlo per tutto il giorno e funge da <em>refresh</em> il giorno dopo il lavaggio.</p><ul><li>Ricci definiti e sciolti</li><li>SENZA effetto arruffato</li><li>Elimina l'effetto <em>crespo</em></li><li>Combinazione perfetta</li></ul>",
        id: 53110,
        sendingClub: 53348,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/pack-rizos-definicion-landing-club.jpg&width=400&height=700",
      },
    ],
    cnmu: [
      {
        title: "All in One",
        summary:
          "Una palette di trucco cruelty free e vegana con la perfetta combinazione di pigmenti minerali per evidenziare, ombreggiare e definire il viso e gli occhi. Contiene 3 ombretti, 2 evidenziatori e 2 polveri per il viso.",
        text: "Con questa palette in edizione speciale, naturale e vegana, troverete tutto il necessario per ottenere un look completo per ogni occasione in soli 5 minuti. ALL-IN-ONE comprende 7 tonalità vellutate e facili da applicare per creare look illimitati, da quelli naturali e sottili a quelli drammatici e audaci. <li>Evidenzia, sfuma e definisce</li><li>Risultati naturali ma fantastici</li><li>7 tonalità in una palette (viso e occhi)</li> ",
        id: 53228,
        sendingClub: 53316,
        options: [
          {
            id: 53229,
            sendingClub: 53310,
            text: "Light",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
          },
          {
            id: 53230,
            sendingClub: 53311,
            text: "Medium",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
          },
          {
            id: 53231,
            sendingClub: 53312,
            text: "Tan",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
          },
        ],
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_all_in_one.png&width=400&height=600",
      },
    ],
    body: [
      {
        title: "The glow",
        summary:
          "Crema corpo nutriente con particelle minerali scintillanti per una pelle soda, liscia, morbida ed elastica.  The Glow grazie alla sua combinazione esclusiva di olio di cocco, jojoba e burro di karité fornisce tutti i nutrienti, idratanti e vitamine di cui la nostra pelle ha bisogno.",
        text: "The Glow ha un esclusivo complesso naturale che stimola la produzione di collagene ed elastina, oltre a neutralizzare l'azione dei radicali liberi, prevenendo l'invecchiamento e ottenendo una pelle soda e giovane. Migliora la funzione protettiva della pelle mantenendo l'idratazione per tutto il giorno e nutrendola intensamente, il che lo rende perfetto per le smagliature, la gravidanza o la pelle che ha bisogno di nutrimento extra.<br><br><li>Idrata, nutre e rassoda la pelle.</li><li>Lascia un sublime bagliore dorato</li><li>Aroma deliziosamente avvolgente</li><li>Stimola la produzione di collagene ed elastina</li><li>Assorbimento rapido.</li>          ",
        id: 53176,
        sendingClub: 53278,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/glow_club.png&width=400&height=790",
      },
      {
        title: "The Extraordinary",
        summary:
          "Olio per il corpo 3 in 1 con oli di mandorla, rosa canina e argan per rendere la tua pelle altamente idratata, radiosa e impeccabile fin dalla prima applicazione.",
        text: "Il suo meraviglioso aroma e la sua facile applicazione rendono il suo utilizzo un momento magico. Inoltre, ha un'importante azione preventiva e curativa contro le smagliature; il che lo rende perfetto per le donne incinte e i cambiamenti di peso.<br><br><li>Idrata, nutre e rigenera.</li><li>Previene e tratta le smagliature</li><li>Rassoda e leviga la pelle</li><li>Perfetto durante la gravidanza</li>",
        id: 52074,
        sendingClub: 53227,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_extraordinary.png&width=400&height=700",
      },
      {
        title: "Body Power",
        summary:
          "<p>BODY POWER reinventa, rinnova e rivoluziona il concetto dei prodotti anticellulite e dimagranti da una prospettiva femminile, onesta, sincera. Due grandi formule progettate per lavorare nei due momenti pi&ugrave; importanti della giornata: prima del tuo allenamento e mentre sei a riposo. Risultati in 56 giorni*.</p>",
        text: "I suoi principi attivi intelligenti clinicamente testati, come la Glucosyl Hesperidine, la Caffeina, la Fisetina, il Chetone di Lampone, il Gingko Biloba o l'Acido Ialuronico (tra gli altri), lavoreranno sugli aspetti fondamentali per ridefinire la tua silhouette. I suoi attivi intelligenti, clinicamente testati, lavoreranno per ridefinire la tua silhouette.I risultati sono impressionanti (risultati in 56 giorni*):<li> Riduce l'aspetto della cellulite.</li><li> Riduce fino a 5,7 cm di circonferenza della coscia*.</li><li> Riduce fino a 5,5 cm di circonferenza dei fianchi*.</li><li> Riduce fino a 5 cm di circonferenza dell'addome*</li><li> Riduce fino a 2,4 cm di circonferenza del braccio*.</li><li>*2 prodotti, ingredienti clinicamente testati su 60 donne e con risultati visibili in 56 giorni.</li>",
        id: 53299,
        sendingClub: 53344,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1649402974082_render-body-power.jpg&width=400&height=700",
      },
    ],
  },
  de: {
    face: [
      {
        title: "The Absolute",
        summary:
          "Die Anti-Aging-Creme mit innovativer, natürlicher Technologie, die den Alterungsprozess kontrolliert GEN NC886, um die Haut zu regenerieren und zu verjüngen",
        text: "Hyaluronsäure mit niedrigem Molekulargewicht und ihre 12 biologisch aktiven Komponenten bekämpfen wirksam die Hautalterung, neutralisieren freie Radikale und polstern Falten auf. Eine Explosion von Nährstoffen, Vitaminen und Proteinen, die die Kollagenproduktion anregen, die Zellen regenerieren und zu einer geschmeidigen, glatten und makellosen Haut führen.<br><br><li>Restrukturiert alle Hauttypen von innen heraus</li><li>Stimuliert die Kollagenproduktion und die Regeneration der Haut.</li><li>Reduziert nasolabiale Falten um 19%.</li><li>Verbessert die Hauternährung um 14%.</li><li>Verringert Hautunreinheiten um 10%.</li>",
        id: 53123,
        sendingClub: 53275,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_absolute.png&width=400&height=700",
      },
      {
        title: "The Cure",
        summary:
          "Das ultimative hautheilende Serum für jedes Alter. Verwandle deine Haut mit 21 einzigartigen, 100% natürlichen Ölen und Bakuchiol, um selbst die tiefsten Falten zu reparieren und zu behandeln.            ",
        text: "4 Schlüssel, um es in eine Sucht zu verwandeln:<p><strong>BAKUCHIOL</strong>: Die natürliche Alternative zu Retinol, die die Kollagen und Elastinproduktion stimuliert, die Melaninproduktion hemmt und selbst die tiefsten Falten repariert, verdichtet und behandelt.</p><p><strong>VITAMIN EXPLOSION</strong>: Kombinierte Wirkung der Vitamine B, C, E und K mit schnell sichtbaren Ergebnissen. Erhellt und glättet die Haut für einen 8-Stunden-Schlafeffekt.</p><p><strong>PRODIGIOUS ENZYME</strong>: Die Superoxid-Dismutase ist ein Zellerneuerer, der Ihre Haut völlig verändern wird. Es neutralisiert freie Radikale und kehrt die Zeichen der Alterung um.</p><p><strong>BOTOX VEGETAL + FETTSÄUREN</strong>: Eine einzigartige Synergie zur Verjüngung Ihrer Haut. Verbessert die Festigkeit durch Glättung tiefer und oberflächlicher Falten.</p> <li>Schillernde Haut</li><li>Füllt Falten</li><li>Stimuliert die Bildung von Kollagen und Hyaluronsäure</li>",
        id: 53252,
        sendingClub: 53224,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_cure.png&width=400&height=700",
      },
      {
        title: "Wondermask",
        summary:
          "5-in-1-Gesichtsmaske für perfekte Haut ohne Filter. Entgiftet, gleicht aus, verfeinert die Poren, peelt und bekämpft die Auswirkungen der Umweltverschmutzung.",
        text: "Die Maske aus natürlichen Komplexen: Feigenkaktus, Rosa Canina, Kamille, Sonnenblume, Ingwer und die Kraft unserer reinen mediterranen Tonerde machen deine Haut in nur wenigen Minuten perfekt und sorgen für eine glatte und reine Haut. <li>Entgiftet und gleicht die Haut aus</li><li>Strafft und verfeinert die Poren</li><li>Beseitigt das Erscheinungsbild eines geschwollenen Gesichts </li><li>Hellt den Hautton auf und gleicht ihn aus</li><li>Schützt vor blauem Licht von Bildschirmen</li><li>Revitalisiert und remineralisiert die Haut.</li>",
        id: 53102,
        sendingClub: 53226,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_wondermask.png&width=400&height=700",
      },
      {
        title: "THE REAL C ",
        summary:
          "Der stärkste Anti-Ageing-Schutz mit 100% reinem Vitamin C (Ascorbinsäure) mit 20%. Es ist bis zu 178 Mal stärker als Vitamin-C-Derivate* und wirkt gegen Anzeichen von Hauterschlaffung, Hautunreinheiten, Falten und fahle Haut. EcoRecharge-Format	<br><small>Nach Stabilitätstests zum Vergleich von THD-Ascorbat und L-Ascorbinsäure.</small>",
        text: "<p>Dieses bis zu 178-mal stärker als Vitamin-C-Derivate* und in Begleitung von Vitamin E wirkende Superkosmetikum dringt in die tiefsten Schichten der Haut ein und verbessert die Kollagensynthese. Diese Synergie bildet einen antioxidativen Komplex, der als Schutzschild gegen freie Radikale wirkt, durch Schutz vor Zellschäden, Wirkung gegen Falten und Straffung der Gesichtskonturen, Steigerung der Elastizität und sichtbare Reduzierung von Hautflecken.</p><p>Bewiesene klinische Ergebnisse nach 8 Wochen:</p><li> Steigerung der Straffheit und Elastizität der Haut um bis zu 6,7 %</li><li> Verbesserung der Leuchtkraft der Haut um bis zu 15,1 %</li><li> Verringerung der Intensität und Größe dunkler Flecken um bis zu 22,9 %.</li><li>Reduzierung der Faltentiefe um bis zu 22,2 %</li><li>Kur für 4 Wochen</li><p>*Nach Stabilitätstests im Vergleich von THD-Ascorbat und L-Ascorbinsäure.</p>",
        id: 139,
        sendingClub: 160,
        options: null,
        img: "https://cdn.shopify.com/s/files/1/0619/1693/8405/files/5Q9A4749__1___1_-removebg-preview.png?v=1687426027",
      },
    ],
    hair: [
      {
        title: "Boombastic",
        summary:
          "Intensiv nährende Haarmaske zur Wiederherstellung dein Haares. Eine Explosion von stärkenden Wirkstoffen, um die letzten 5 cm zu pflegen und Spliss zu verhindern.",
        text: "Seine einzigartige Formel, die reich an Laurinsäure, Linolsäure und Carotinoiden ist, dringt in den Haarschaft ein, verhindert Proteinverlust und stärkt die Haarfaser. Enthält spezielle wasserabweisende Komponenten, die die Feuchtigkeit aus der Schuppenschicht bloquiert und so das Kräuseln verhindern. <li>Glänzendes, genährtes und kräftiges Haar.</li><li>Intensive Feuchtigkeitszufuhr und Nährstoffversorgung</li><li>Repariert geschädigtes und trockenes Haar</li><li>Kontrolliert Frizz</li><li>Geprüfte Lockenmethode.</li>",
        id: 53183,
        sendingClub: 53225,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_boombastic.png&width=400&height=600",
      },
      {
        title: "Savior",
        summary:
          "Der neueste Fortschritt in der wissenschaftlichen Haarinnovation auf der Basis veganer Moleküle, die die molekulare Struktur der Haarfaser wiederherstellen und reparieren und so die Gesundheit und Stärke deines Haares wiederherstellen (chemische Schäden-Färbemittel, physikalische-Styling, Umwelt oder thermische-Glatteisen). NACHGEWIESENE KLINISCHE WIRKSAMKEIT.",
        text: "SAVIOR ist die Synergie von 2 Produkten: FIXER, mit dem wir arbeiten und die Faser wiederherstellen. Und SMOOTHER, mit dem wir Feuchtigkeit, Flexibilität und Geschmeidigkeit zurückgewinnen und erneuern. Damit erzielst du schon bei der ersten Anwendung sichtbare Ergebnisse.NACHGEWIESENE KLINISCHE WIRKSAMKEIT*:<li>9 von 10 zufriedenen Nutzern *.</li><li>91% wiederhergestelltes Haar in 4 Anwendungen*.</li><li>96% Wiederherstellung des natürlichen Glanzes* </li><li>96% weicheres, geschmeidigeres Haar* </li><li>87% mehr Feuchtigkeit für das Haar* </li><li>87% mehr Feuchtigkeit im Haar, stärker und widerstandsfähiger *</li>",
        id: 53239,
        sendingClub: 53274,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_savior_new.png&width=400&height=700",
      },
      {
        title: "Curly Method",
        summary:
          " Cocunat ist weltweit für seine Lockenprodukte bekannt. Dieses Set  erfindet die CURLY-METHODE neu, um sie einfacher, effektiver und für alle lockigen Haare geeignet zu machen. Nur 2 Schritte, um das Haar zu definieren, zu hydratisieren, zu nähren und Frizz für immer loszuwerden.",
        text: "Jedes Produkt in diesem Set bietet spezifische Aspekte, die je nach Haartyp zu einer spektakulären Lockenpracht führen: CURL MOISTURIZER LEAVE-IN entwirrt, definiert und spendet intensiv Feuchtigkeit dank seiner speziellen Formulierung auf der Basis von Argan, Jojoba und Abessinia-Öl, die dem Wasser<li>und Feuchtigkeitsverlust entgegenwirken. Der CURL BOOSTER definiert und pflegt die Locken, ohne sie zu beschweren, und beseitigt den Frizz-Effekt dank seiner mit Kokosnuss , Argan , Grapefruit , Mandarinen , Orangen , Minze und Rosmarinöl angereicherten Formel.</li><li>Definiert</li><li>Nährt und spendet Feuchtigkeit</li><li>Beseitigt Frizz</li><li>Geeignet für die Curly Girl Methode.</li>",
        id: 53055,
        sendingClub: 53309,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_cmc.png&width=400&height=700",
      },
      {
        title: "Pack: LOCKEN DEFINITION EXTRA CLUB",
        summary:
          "<p>Die ultimative Produktkombination, um lockiges Haar zu <strong>definieren </strong>und <strong>in Form zu halten</strong>, ohne die Locken zu beschädigen oder zu beschweren - alles, was du in deiner Pflegeroutine für <strong>eine lockige, definierte, genährte und <em>frizzfreie </em>Haarpracht brauchst!</strong></p>",
        text: "<p>Warum dieses Produkt zu ein Muss für deine Curly-Haarpflege wird:</p><p>Dieses Pack ist die perfekte Ergänzung, um deine Locken unverwechselbar, flexibel, locker, definiert und unverwüstlich zu machen, ohne sie zu beschweren. Es besteht aus:</p><p><strong>CURLY-METHODE by COCUNAT und CURL SPRAY</strong></p><p>Der <strong>CURL MOISTURIZER LEAVE-IN entwirrt, definiert</strong> und spendet intensiv <strong>Feuchtigkeit </strong>dank seiner speziellen Formulierung auf Basis von <strong>Argan-, Jojoba-</strong> und <strong>Abessinieröl</strong>, die dem Feuchtigkeitsverlust entgegenwirken.</p><p>Der <strong>CURL BOOSTER</strong> <strong>definiert </strong>und <strong>nährt </strong>die Locken, ohne sie zu beschweren, und befreit sie vom <em>Frizz</em>-Effekt dank seiner mit <strong>Kokosnuss-, Argan-, Grapefruit-, Mandarinen-, Orangen-, Minze-</strong> und <strong>Rosmarinöl </strong>angereicherten Formel.</p><p><strong>CURL SPRAY</strong> ist ein natürliches und effektives Fixiermittel für alle, die nicht nur ihre Locken mit dem Curl Booster definieren, sondern auch für einen <strong>besseren Halt</strong> sorgen wollen. Es hält den ganzen Tag und kann am Tag nach dem Waschen als <em>Refresh </em>dienen.</p><ul><li>Definierte und lässige Locken</li><li>KEIN Verklumpen</li><li>Beseitigt den <em>Frizz</em>-Effekt</li><li>Die perfekte Ergänzung</li></ul>",
        id: 53110,
        sendingClub: 53348,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/pack-rizos-definicion-landing-club.jpg&width=400&height=700",
      },
    ],
    cnmu: [
      {
        title: "All in One",
        summary:
          "Eine tierversuchsfreie und vegane Make-up-Palette mit der perfekten Kombination aus mineralischen Pigmenten zum Hervorheben, Schattieren und Definieren von Gesicht und Augen. Sie enthält 3 Lidschatten, 2 Highlighter und 2 Gesichtspuder.            ",
        text: "Mit dieser natürlichen und veganen Sonderedition der Palette findest du  alles, was du brauchst, um in nur 5 Minuten einen kompletten Look für alle Gelegenheiten zu kreieren. ALL-IN-ONE enthält 7 samtige, leicht aufzutragende Farbtöne, mit denen du  unbegrenzte Looks kreieren kannst, von natürlich und dezent bis hin zu dramatisch und gewagt. <li>Hervorheben, schattieren und definieren</li><li>Natürliches und dennoch fantastisches Ergebnis</li><li>7 Farbtöne in einer Palette (Gesicht und Augen)</li>          ",
        id: 53228,
        sendingClub: 53316,
        options: [
          {
            id: 53229,
            sendingClub: 53310,
            text: "Light",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-A.jpg",
          },
          {
            id: 53230,
            sendingClub: 53311,
            text: "Medium",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-B.jpg",
          },
          {
            id: 53231,
            sendingClub: 53312,
            text: "Tan",
            img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cnmu-quiz-photos/cnmu-quiz-C.jpg",
          },
        ],
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/club_all_in_one.png&width=400&height=600",
      },
    ],
    body: [
      {
        title: "The glow",
        summary:
          "Pflegende Körpercreme mit schimmernden Mineralpartikeln für eine feste, glatte, weiche und elastische Haut.  The Glow liefert dank der exklusiven Kombination aus Kokosnussöl, Jojoba und Sheabutter alle Nährstoffe, Feuchtigkeitsspender und Vitamine, die unsere Haut braucht. ",
        text: "The Glow enthält einen exklusiven natürlichen Komplex, der die Produktion von Kollagen und Elastin anregt und die Wirkung freier Radikale neutralisiert, um der Hautalterung vorzubeugen und eine straffe und jugendliche Haut zu erhalten. Verbessert die Schutzfunktion der Haut, indem sie die Feuchtigkeit den ganzen Tag über aufrechterhält und sie intensiv nährt, was sie perfekt für Dehnungsstreifen, Schwangerschaft oder für Haut macht, die zusätzliche Nährstoffe benötigt.Versorgt die Haut mit Feuchtigkeit, nährt und strafft sie.Hinterlässt einen sublimen goldenen SchimmerKöstlich umhüllendes Aroma<li>Stimuliert die Kollagenund Elastinproduktion</li><li>Zieht schnell ein</li>          ",
        id: 53176,
        sendingClub: 53278,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/cocunat-club/glow_club.png&width=400&height=790",
      },
      {
        title: "The Extraordinary",
        summary:
          "3 in 1 Körperöl auf der Basis von Mandel, Hagebutten und Arganöl, das deine Haut von der ersten Anwendung an mit Feuchtigkeit versorgt und ihr ein strahlendes Aussehen verleiht.",
        text: "Sein großartiger Duft und seine einfache Anwendung machen die Anwendung zu einem magischen Moment. Darüber hinaus hat es eine wichtige vorbeugende und behandelnde Wirkung gegen Dehnungsstreifen, was es perfekt für schwangere Frauen und Gewichtsveränderungen macht.<br><br><li>Spendet Feuchtigkeit, nährt und regeneriert.</li><li>Beugt Dehnungsstreifen vor und behandelt sie</li><li>Stärkt die Haut und macht sie geschmeidig</li><li>Perfekt während der Schwangerschaft</li>",
        id: 52074,
        sendingClub: 53227,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/club_extraordinary.png&width=400&height=700",
      },
      {
        title: "Body Power",
        summary:
          "<p>BODY POWER kommt, um das Konzept der Anti-Cellulite- und Slimming-Produkte aus einer weiblichen, ehrlichen, aufrichtigen und holistischen Perspektive neu zu erfinden, zu erneuern und zu revolutionieren. Zwei gro&szlig;artige Formeln, die f&uuml;r die beiden wichtigsten Momente des Tages entwickelt wurden: vor dem Sport und in der Ruhephase.</p>",
        text: "Ihre klinisch getesteten, intelligenten Wirkstoffe, wie Glucosyl Hesperidine, Koffein, Fisetin &amp; Himbeerketon, Gingko Biloba und Hyalurons&auml;ure (unter anderem), wirken auf die grundlegenden Aspekte, um Ihre Silhouette neu zu definieren. Ergebnisse in 56 Tagen*:<li> Reduziert das Erscheinungsbild von Cellulite.</li><li> Verringert bis zu 5,7 cm Oberschenkelumfang*.</li><li> Verringert bis zu 5,5 cm H&uuml;ftumfang*.</li><li> Verringert bis zu 5 cm Bauchumfang*</li><li> Verringert bis zu 2,4 cm Armumfang*</li><li>*2 Produkte, klinisch getestete Inhaltsstoffe an 60 Frauen und sichtbare Ergebnisse in 56 Tagen.</li>",
        id: 53299,
        sendingClub: 53344,
        options: null,
        img: "https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/photos/1649402974082_render-body-power.jpg&width=400&height=700",
      },
    ],
  },
};

export default ClubProductsInfo;
